import { Injectable } from '@angular/core';
import {ApiService} from "../../../core/services/api.service";
import {Observable} from "rxjs";
import {OrganitzationDTO} from "../../login/resources/organitzationDTO";
import {UserDTO} from "../../login/resources/user";


@Injectable()
export class UsersConfigService {
  constructor(
  private _apiService:ApiService) {
  }
  public getAllOrganitzations(): Observable<OrganitzationDTO[]> {
    return this._apiService.get('/Users/allUsersConfig' );
  }
  public getUserOrg(organitzationId: number): Observable<UserDTO[]> {
    return this._apiService.get('/Users/getUserOrg/' + organitzationId );
  }




}
